import React from "react";
import ClientsData from "data/sections/clients.json";
import Split from "../Split";

const Clients = ({ theme }) => {
  var first = ClientsData.slice(0, 3); // First 3 clients
  var second = ClientsData.slice(3, 7); // Next 4 clients
  var third = ClientsData.slice(7, 11); // Next 4 clients

  return (
    <section className="clients section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 valign md-mb50">
            <div className="sec-head mb-0">
              <h3 className="wow mb-20 color-font">Our IoT Partners</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti voluptatum sit commodi provident rerum? A blanditiis sequi ali
              </p>
            </div>
          </div>
          <div className="col-lg-8">
            <div>
              <div className="row bord">
                {first.map((item) => (
                  <div key={item.id} className="col-md-4 col-6 brands">
                    <div
                      className="item wow fadeIn"
                      data-wow-delay={`${
                        item.id === 1
                          ? ".3"
                          : item.id === 2
                          ? ".6"
                          : item.id === 3
                          ? ".8"
                          : ""
                      }s`}
                    >
                      <div className="img">
                        {theme === "light" ? (
                          <img src={item.lightImage} alt="" />
                        ) : (
                          <img src={item.darkImage} alt="" />
                        )}
                        <Split>
                          <a
                            href="#0"
                            className="link words chars splitting"
                            data-splitting
                          >
                            {item.url}
                          </a>
                        </Split>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row bord">
                {second.map((item) => (
                  <div key={item.id} className="col-md-3 col-6 brands">
                    <div
                      className="item wow fadeIn"
                      data-wow-delay={`${
                        item.id === 4
                          ? ".4"
                          : item.id === 5
                          ? ".7"
                          : item.id === 6
                          ? ".5"
                          : item.id === 7
                          ? ".6"
                          : ""
                      }s`}
                    >
                      <div className="img">
                        {theme === "light" ? (
                          <img src={item.lightImage} alt="" />
                        ) : (
                          <img src={item.darkImage} alt="" />
                        )}
                        <Split>
                          <a
                            href="#0"
                            className="link words chars splitting"
                            data-splitting
                          >
                            {item.url}
                          </a>
                        </Split>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                {third.map((item) => (
                  <div key={item.id} className="col-md-3 col-6 brands">
                    <div
                      className="item wow fadeIn"
                      data-wow-delay={`${
                        item.id === 8
                          ? ".4"
                          : item.id === 9
                          ? ".7"
                          : item.id === 10
                          ? ".5"
                          : item.id === 11
                          ? ".6"
                          : ""
                      }s`}
                    >
                      <div className="img">
                        {theme === "light" ? (
                          <img src={item.lightImage} alt="" />
                        ) : (
                          <img src={item.darkImage} alt="" />
                        )}
                        <Split>
                          <a
                            href="#0"
                            className="link words chars splitting"
                            data-splitting
                          >
                            {item.url}
                          </a>
                        </Split>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
