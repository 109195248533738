import React from "react";
import { Link } from 'gatsby';
import cardMouseEffect from "common/cardMouseEffect";

const Services3 = () => {
  React.useEffect(() => {
    cardMouseEffect(document.querySelectorAll(".feat .items"));
  }, []);
  return (
    <section className="feat sub-bg section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
              Connectified
              </h6>
              <h3 className="wow color-font">
              Already have IoT?
              </h3>
              <h5>We can help to get you connected onto the cellular LTE network with the peace of mind your solution will work as intended and importantly within budget.</h5>

              <Link to="/contact/contact-light" className="butn bord curve mt-30">
                <span>Get Connected</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 items md-mb30">
            <div className="item wow fadeIn" data-wow-delay=".3s">
              <span className="icon">
                <i className="ion-ios-monitor"></i>
              </span>
              <h5>Free-of-cost SIM test kits
</h5>
              {/* <p>
                Implementation and rollout of new network infrastructure,
                including consolidation.
              </p> */}
  
              {/* <Link to="/about/about-dark" className="more-stroke">
                <span></span>
              </Link> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-6 items active md-mb30">
            <div className="item wow fadeIn" data-wow-delay=".3s">
              <span className="icon">
                <i className="ion-ios-bolt-outline"></i>
              </span>
              <h5>No roaming latency</h5>
              {/* <p>
                Implementation and rollout of new network infrastructure,
                including consolidation.
              </p> */}
              {/* <Link to="/contact/contact-light" className="more-stroke">
                <span></span>
              </Link> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-6 items sm-mb30">
            <div className="item wow fadeIn" data-wow-delay=".3s">
              <span className="icon">
                <i className="ion-cube"></i>
              </span>
              <h5>Bulk SIM purchasing</h5>
              {/* <p>
                Implementation and rollout of new network infrastructure,
                including consolidation.
              </p> */}
              {/* <Link to="/about/about-dark" className="more-stroke">
                <span></span>
              </Link> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-6 items">
            <div className="item wow fadeIn" data-wow-delay=".3s">
              <span className="icon">
                <i className="ion-ios-color-wand"></i>
              </span>
              <h5>Guaranteed low rates</h5>
              {/* <p>
                Implementation and rollout of new network infrastructure,
                including consolidation.
              </p> */}
              {/* <Link to="/about/about-dark" className="more-stroke">
                <span></span>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services3;
